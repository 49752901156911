.slide {
	img {
		max-width: 80%;
		opacity: 0.5;
		transition: 0.3s;
		padding: 15px 0;

		&:hover {
			opacity: 1;
			-webkit-transform: scale(1.1);
			transform: scale(1.1);
		}
	}
}

.trans {
	transition-duration: 5s !important;
}
