@use 'src/shared/styles/tools/mixins';
@import 'src/shared/styles/settings/colors';

.list {
	margin-top: 35px !important;
	text-align: center;
}

.separatorBorder {
	@include mixins.bootstrap-media-breakpoint-up(md) {
		border-right: 1px solid $contact-line-separator;
	}
}
