// Base
$black: #000000;
$white: #ffffff;
$success: #198754;
$info: #0dcaf0;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #212529;

// Palette Theme
$primary-color: #111111;
$primary-color-dark: $black;
$primary-color-light: #343b40;
$primary-text: $white;

$secondary-color: #0098da;
$secondary-color-dark: #006aa8;
$secondary-color-light: #60c9ff;
$secondary-text: $white;

// Brand Theme
$body-bg: $white;
$body-color: #666666;
$section-background-striped: #f7f7f7;
$section-background-striped-dark: #ddd;
$section-line-header: #ddd;
$contact-line-separator: #ddd;
$card-shadow: #4458901a;
$icon-color: #1e1e1e;
$icon-shadow: #cde1f8;
$hover-bg-shade-amount: 30%;
$header-bg-alpha-amount: 0.9;
$backdrop-color: #1e1e1e;
$border-color: #ced4da;
