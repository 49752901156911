@import 'src/shared/styles/settings/colors';

.title {
	color: $primary-color;
	font-weight: 700;
	font-size: 32px;
	margin: 0 0 20px 0;
}

.text {
	line-height: 26px;
	margin-bottom: 55px;
	margin-right: 8px;
	text-align: justify;
}
