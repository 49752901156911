@use '../tools/functions';
@import '../settings/colors';

.btn {
	border-radius: 0.375rem;
	padding: 0.5rem 1.3rem;
	transition: 0.5s;

	&.btn-secondary {
		color: $secondary-text;
	}

	&:hover {
		background-color: functions.bootstrap-shade-color($secondary-color, $hover-bg-shade-amount);
	}
}
