@import 'src/shared/styles/settings/colors';

.btnLink {
	text-transform: uppercase;
	text-decoration: none;
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 1px;
	display: inline-block;
	padding: 0.5rem 1.3rem;
	border-radius: 0.375rem;
	transition: 0.5s;
}

.btnFilled {
	background: $secondary-color;
	border: 2px solid $secondary-color;
	color: $secondary-text;

	&:hover {
		background: none;
		border: 2px solid $white;
		color: $white;
	}
}

.btnUnfilled {
	background: none;
	border: 2px solid $white;
	color: $white;

	&:hover {
		background: $secondary-color;
		border: 2px solid $secondary-color;
		color: $secondary-text;
	}
}
