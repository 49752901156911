@import '../settings/colors';

.tns-outer .tns-nav {
	margin-top: 12px;
	text-align: center;

	button {
		display: inline-block;
		margin: 0 5px;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background-color: $section-background-striped-dark;
		border: none;
		padding: 0 !important;

		&.tns-nav-active {
			background-color: $secondary-color;
		}

		&:focus {
			outline: 5px auto -webkit-focus-ring-color;
		}
	}
}
