@use 'src/shared/styles/tools/mixins';
@import 'src/shared/styles/settings/colors';

.section {
	display: table;
	width: 100%;
	height: 100vh;
	background: url(./../../../../assets/images/home-background.jpg) top center;
	background-size: cover;
	padding: 0px;

	@include mixins.bootstrap-media-breakpoint-up(lg) {
		background-attachment: fixed;
	}
}

.container {
	background: rgba($black, 0.8);
	display: table-cell;
	margin: 0;
	padding: 0;
	text-align: center;
	vertical-align: middle;
}
