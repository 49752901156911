@import 'src/shared/styles/settings/colors';

.form {
	.formRow {
		margin-right: -5px !important;
		margin-left: -5px !important;

		[class*='col-'] {
			padding-right: 5px !important;
			padding-left: 5px !important;
			margin-bottom: 0.3rem;
		}
	}
}

.formEmailAlertErrorLink {
	transition: 0.5s;

	&:hover {
		color: $secondary-color !important;
	}
}
