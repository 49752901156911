.form-floating {
	input[type='text'].form-control {
		height: calc(2.5rem + 4px);
		min-height: calc(2.5rem + 4px);
	}

	input[type='text'].form-control + label,
	input[type='text'].form-control + .invalid-feedback + label {
		padding: 0.6rem 1rem;
		&::after {
			inset: auto;
		}
	}

	textarea.form-control + label,
	textarea.form-control + .invalid-feedback + label {
		padding-left: 1rem;
	}
}
