@import 'src/shared/styles/settings/colors';

.section {
	background: linear-gradient(rgba($black, 0.6), rgba($black, 0.6)),
		url(./../../../../assets/images/phrase-background.jpg) fixed center center;
	background-size: cover;
	padding: 80px 0;
}

.title {
	color: $white;
	font-size: 35px;
	font-weight: 700;
	margin: 0 0 30px 0;
}

.text {
	font-size: 1.3rem;
	color: $white;
	margin: 10px 0 25px 0;
}
