.form-control {
	+ .invalid-feedback {
		visibility: hidden;
		display: block !important;
		opacity: 0;
		transform: translateY(-50%);
		transition: all 0.5s ease;
		&::after {
			content: '.';
		}
	}

	&.is-invalid {
		+ .invalid-feedback {
			visibility: initial;
			opacity: 1;
			transform: translateY(0%);
		}
	}
}
