$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	xxl: 1140px
);

.container {
	padding-right: 15px !important;
	padding-left: 15px !important;

	.row {
		margin-right: -15px;
		margin-left: -15px;
	}

	[class*='col-'] {
		padding-right: 15px;
		padding-left: 15px;
	}
}

$grid-gutter-width: 0px;
$gutters: (
	0: 0,
	1: 0,
	2: 0,
	3: 0,
	4: 0,
	5: 0
);
