@import 'src/shared/styles/settings/colors';

.title {
	font-size: 32px;
	color: $primary-color;
	text-transform: uppercase;
	text-align: center;
	font-weight: 700;
	position: relative;
	padding-bottom: 15px;
	margin: 0 0 20px 0;

	&::before {
		content: '';
		position: absolute;
		display: block;
		width: 120px;
		height: 1px;
		background: $section-background-striped-dark;
		bottom: 1px;
		left: calc(50% - 60px);
	}

	&::after {
		content: '';
		position: absolute;
		display: block;
		width: 40px;
		height: 3px;
		background: $secondary-color;
		bottom: 0;
		left: calc(50% - 20px);
	}
}

.description {
	text-align: center;
	margin-bottom: 40px;
}
