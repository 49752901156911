@import 'src/shared/styles/settings/colors';

.item {
	position: relative;
	padding: 30px;
	overflow: hidden;
	border-radius: 10px;
	margin: 0 10px 40px 10px;
	background: $white;
	box-shadow: 0 10px 29px 0 $card-shadow;
	transition: all 0.3s ease-in-out;
	text-align: center;

	&:hover {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);

		.icon {
			transition: all 500ms ease;
			color: $white;
			background-color: $secondary-color;
			box-shadow: 0 0 0 10px $icon-shadow;
		}
	}
}

.title {
	color: $primary-color;
	font-weight: 700;
	margin-top: 20px;
	margin-bottom: 15px;
	text-transform: uppercase;
}

.description {
	line-height: 24px;
	margin-bottom: 0;
	text-align: justify;
}

.icon {
	display: block;
	color: $icon-color;
	height: 60px;
	width: 60px;
	font-size: 2rem;
	border-radius: 50%;
	line-height: 1.9;
	margin: 0 auto;
	box-shadow: 0 0 0 4px $secondary-color;
	transition: all 500ms ease;
}
