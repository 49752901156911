@use 'src/shared/styles/tools/mixins';
@import 'src/shared/styles/settings/colors';

.title {
	margin: 30px 0 10px 0;
	font-weight: 700;
	line-height: 48px;
	text-transform: uppercase;
	color: $primary-text;

	@include mixins.bootstrap-media-breakpoint-down(md) {
		font-size: 28px;
		line-height: 36px;
	}
}

.text {
	color: $secondary-color;
	margin-bottom: 50px;

	@include mixins.bootstrap-media-breakpoint-down(md) {
		font-size: 24px;
		line-height: 26px;
		margin-bottom: 30px;
	}
}

.rotatingText {
	color: $primary-text;

	div {
		@include mixins.bootstrap-media-breakpoint-down(md) {
			display: block !important;
		}
	}
}
