@use 'src/shared/styles/tools/functions';
@import 'src/shared/styles/settings/colors';
@import 'src/shared/styles/settings/z-index';

.container {
	position: fixed;
	z-index: $z-float-button;
}

.button {
	position: fixed;
	background: $secondary-color;
	color: $secondary-text;
	width: 44px;
	height: 44px;
	border-radius: 50%;
	right: 15px;
	bottom: 15px;
	text-align: center;
	line-height: 1;
	cursor: pointer;
	transition: all 0.5s ease;

	&:focus {
		color: $secondary-text;
		outline: none;
	}

	&:hover {
		background: functions.bootstrap-shade-color($secondary-color, $hover-bg-shade-amount);
		color: $secondary-text;
	}
}

.icon {
	font-size: 19px;
	padding-top: 12px;
}
