@use 'src/shared/styles/tools/mixins';
@use 'src/shared/styles/tools/functions';
@import 'src/shared/styles/settings/colors';

.item {
	padding-top: 15px;
	padding-bottom: 25px;
	@include mixins.bootstrap-media-breakpoint-up(md) {
		padding: 35px 0;
	}
}

.icon {
	font-size: 36px;
	color: $secondary-color;
	margin-bottom: 25px;
	transition: 0.5s;

	&:hover {
		color: functions.bootstrap-shade-color($secondary-color, $hover-bg-shade-amount);
	}
}

.title {
	font-size: 18px;
	margin-bottom: 15px;
	font-weight: bold;
	text-transform: uppercase;
	color: tint-color($black, 60%);
}

.linkCaption {
	color: $secondary-color;
	line-height: 1.125em;
	transition: 0.5s;

	&:hover {
		font-size: large;
		font-weight: bold;
	}
}
