@use 'src/shared/styles/tools/mixins';
@import 'src/shared/styles/settings/colors';

.section {
	padding: 0px !important;
}

.header {
	height: 72px;
}

.sticky {
	background: $primary-color;
}

.stickyActive {
	background: rgba($primary-color-light, $header-bg-alpha-amount);
}

.navMenu {
	a {
		padding: 10px 22px 10px 0px;
		margin-left: 0px;
		color: $white !important;
		font-weight: 500;
		text-transform: uppercase;
		font-size: 13px;
		cursor: pointer;
	}

	@include mixins.bootstrap-media-breakpoint-up(lg) {
		a {
			margin-left: 35px;
			position: relative;

			&:before {
				content: '';
				position: absolute;
				width: 100%;
				height: 2px;
				bottom: 0;
				left: 0;
				background-color: $secondary-color;
				visibility: hidden;
				-webkit-transform: scaleX(0);
				transform: scaleX(0);
				-webkit-transition: all 0.3s ease-in-out 0s;
				transition: all 0.3s ease-in-out 0s;
			}

			&:hover {
				color: $white;

				&:before {
					visibility: visible;
					-webkit-transform: scaleX(1);
					transform: scaleX(1);
				}
			}
		}
	}
}

.navBrand {
	padding: 0;

	img {
		max-height: 40px;
		cursor: pointer;

		@include mixins.bootstrap-media-breakpoint-up(md) {
			max-height: 50px;
		}
	}
}

.mobileNav {
	background: rgba($primary-color-light, $header-bg-alpha-amount) !important;
	width: 260px !important;
	transition: 0.4s;

	.offcanvasHeader {
		justify-content: flex-end;
		padding-right: 0px;
		padding-bottom: 0px;
		padding-top: 10px;
	}
	.offcanvasBody {
		padding-top: 0px;
	}
}

.mobileNavToggleButton {
	position: fixed;
	right: 0;
	top: 0;
	margin: 20px 15px 0 0;
	border: 0 !important;
}

.mobileNavCloseButton {
	background: none;
	border: 0 !important;
	&:hover {
		background-color: unset !important;
	}
}

.mobileNavIcon {
	color: $white;
	font-size: 24px;
}

.mobileNavBackdrop {
	background: rgba($primary-color-light, $header-bg-alpha-amount) !important;
	opacity: 1 !important;
}
