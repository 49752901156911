@import 'src/shared/styles/settings/colors';

.item {
	padding: 0 0 30px 0;

	&:hover {
		.icon {
			background: $secondary-color;
			transition: all 500ms ease;
		}

		svg {
			color: $white;
		}
	}
}

.title {
	color: $primary-color;
	margin-left: 80px;
	font-weight: 600;
	margin-bottom: 5px;
	font-size: 18px;
	text-transform: uppercase;
}

.text {
	margin-left: 80px !important;
	margin-right: 8px !important;
	line-height: 24px;
	text-align: justify;
	margin: 0 0 30px 0;
}

.icon {
	float: left;
	background: $white;
	padding: 16px;
	border-radius: 50%;
	border: 2px solid $secondary-color;
	transition: all 500ms ease;

	svg {
		color: $icon-color;
		font-size: 24px;
	}
}
