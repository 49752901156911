@import 'src/shared/styles/settings/colors';
@import 'src/shared/styles/settings/z-index';

.container {
	position: fixed;
	z-index: $z-preloader;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: visible;
}
.preloader {
	width: 100%;
	height: 100%;
	background: $backdrop-color url('./../../../../assets/images/preloader.svg') no-repeat center center;
}
