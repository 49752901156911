@use 'src/shared/styles/tools/mixins';

.aboutImg {
	height: 100%;
	display: flex;
	align-items: center;
	overflow: hidden;

	@include mixins.bootstrap-media-breakpoint-down(md) {
		height: auto;
	}

	img {
		max-width: 100%;
		@include mixins.bootstrap-media-breakpoint-down(md) {
			margin-left: 0;
			padding-bottom: 30px;
		}
	}
}
